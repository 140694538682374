import React from 'react';
import axios from 'axios';
import Helper from '../../Helper';
import Text from './../../Models/Text';
import ProgressBar from '../ProgressBar';

class VideoProgress extends React.Component {
    interval = null;
    constructor(props, context) {
        super(props, context);

        this.state = {
            progress: null
        }
    }

    componentDidMount() {
        this.interval = setInterval(this.load, 30000);
        this.load();
    }

    componentWillUnmount(){
        clearInterval(this.interval);
    }

    load = () => {
        axios.get('/videoapi/user/progress', {
            params: {
                videoID: this.props.videoID,
                videoType: this.props.videoType
            }
        })
        .then((response) => {
             if(response.data.progress >= this.state.progress) this.setState({ progress: response.data.progress })
        });
    }

    render() {
        // ==============================
        // Render
        // ==============================
        return <div className='video-progress'>
            <ProgressBar progress={this.state.progress} label={ Text.video.combined_progress } />
        </div>
    }
}

export default VideoProgress;