import $ from "jquery";

import '../../../files/theme/js/script';

import React from 'react';
import { render } from 'react-dom';
import VideoApp from './Components/VideoApp';
import ElearningStatusApp from './Components/ElearningStatusApp';
import './NavigationProgress';
import './imports/getResponseFix';

let players = document.getElementsByClassName('videoplayer');

if(players) {
    [].forEach.call(players, function (player) {
        render(<VideoApp playerDOM={player} />, player);
    });
}

(function (){
    let objElearningStatus = document.getElementById('elearning-status');
    if (objElearningStatus) {
        let strType = objElearningStatus.dataset.type ? objElearningStatus.dataset.type : '';
        render(<ElearningStatusApp type={strType}/>, document.getElementById('elearning-status'));
    }
})();