import React from 'react';
import Text from './../../Models/Text';
import VideoItem from './VideoItem';
import Helper from './../../Helper';

class YearItem extends React.Component {
    handlePrintClick = (event) => {
        event.preventDefault();

        window.print();
    }
    render() {
        const videos = this.props.items.map((video)=>{
            return <VideoItem key={video.title} video={video} />;
        });

        return <div className="year-item" data-year={this.props.year}>
            <a className="print" href="#print" onClick={ this.handlePrintClick }>
                <i className="fa fa-print" />
                { Text.elearning_status.print }
            </a>

            <h2>{ Text.elearning_status.watched_videos_year + ' ' + this.props.year }</h2>

            <div className="tableheader flexrow">
                <div className="col">{ Text.elearning_status.length }</div>
                <div className="col">{ Text.elearning_status.length_watched }</div>
                <div className="col">{ Text.elearning_status.status }</div>
                <div className="col">{ Text.elearning_status.watched_date }</div>
            </div>

            <div>{ videos }</div>
            
            <div className="year-item-footer hide-print">
                { Text.elearning_status.year_length_desc }
                <strong>{ Helper.secondsToHm(this.props.lengthWatched) + ' ' + Text.elearning_status.hours }</strong>
            </div>

            <div className="year-item-footer show-print">
                { Text.elearning_status.year_length_desc }
                <strong>{ Helper.secondsToHm(this.props.lengthWatchedCompleteVideo) + ' ' + Text.elearning_status.hours }</strong>
            </div>
        </div>;
    }
}

export default YearItem;
