import React from 'react';
import Text from './../Models/Text';

class ProgressBar extends React.Component {
    render() {

        let progress = this.props.progress;
        let answered = this.props.questionsAnswered;

        let progressBarStyle;
        let progressClasses = ['progress'];
        if(progress != null){
            progressBarStyle = {width: this.props.progress + '%'};
            progressClasses.push('active');
            if(progress < 30){
                progressClasses.push('red');
            }
            if(progress >= 30){
                progressClasses.push('orange');
            }
            if(progress < 50 && progress < 30){
                progressClasses.push('short');
            }
            if(progress === 100){
                progressClasses.push('full');
            }
        }

        if (answered !== '1') {
            progressClasses.push('not-answered');
        }

        let progressBarClasses = ['progress-bar'];
        let progressPercentage = parseFloat(this.props.progress).toFixed(0);

        return <div className={progressBarClasses.join(' ')}>
                <strong>{this.props.label}</strong>
                <div className={progressClasses.join(' ')}>
                    <span style={progressBarStyle}>
                        <strong>{progressPercentage + '%'}</strong>
                    </span>
                </div>
            </div>
    }
}

export default ProgressBar;