import React from 'react';
import Text from './../../Models/Text';
import ProgressBar from './../ProgressBar';
import Helper from './../../Helper';
import {vsprintf} from 'sprintf-js';

class VideoItem extends React.Component {
    render() {
        let subVideos   = [];
        let videos      = (typeof this.props.video.videos !== 'undefined' ? Object.values(this.props.video.videos) : []);
        let extraVideoList, lastWatchedDate, questionAnswerCorrect;
        let videoItemClasses = ['video-item'];

        if(this.props.className) videoItemClasses.push(this.props.className.split(' '));

        if(videos.length > 0) {
            videoItemClasses.push('collection');

            videos.forEach((video) => {
                subVideos.push(<VideoItem className="sub-item" key={'sub-' + video.id} video={video} />);
            });

            extraVideoList = <div className='video-list'>{subVideos}</div>
        }

        if(this.props.video.date) {
            let lastWatchDateClasses = ['col'];
            // if(this.props.video.training_in_collection) lastWatchDateClasses.push('show-print');
            lastWatchedDate = this.props.video.date !== '01.01.1970' ? <div className={lastWatchDateClasses.join(' ')}>{this.props.video.date}</div> : <div className={lastWatchDateClasses.join(' ')}></div>;
            if (this.props.video.hasOwnProperty('videos')) {
                lastWatchedDate = <div className={lastWatchDateClasses.join(' ')}></div>;
            }
        }

        let title = <strong>{ this.props.video.title }</strong>

        if(this.props.video.link) {
            title = <strong><a href={ this.props.video.link }>{ this.props.video.title }</a></strong>
        }

        /**
         * Show badge for solved questions
         */
        let questions, printMessage;
        let questionWrapClasses = ['wrap', 'hide-print'];

        if(this.props.video.num_questions > 0 && this.props.video.percentWatched >= 100) {
            if(this.props.video.answer_correct == '1') {
                questionAnswerCorrect   = Text.questions.elearning_status_solved;
                printMessage            = vsprintf(Text.questions.elearning_print_message, [this.props.video.num_questions]);
                questionWrapClasses.push('correct');
            } else if(this.props.video.answer_correct == '0') {
                questionAnswerCorrect   = Text.questions.elearning_status_not_solved;
                printMessage            = vsprintf(Text.questions.elearning_print_message_fail, [this.props.video.num_questions]);
                questionWrapClasses.push('not-correct');
            } else {
                questionAnswerCorrect   = Text.questions.elearning_status_not_answered;
                printMessage            = vsprintf(Text.questions.elearning_print_message_fail_answered, [this.props.video.num_questions]);
                questionWrapClasses.push('not-answered');
            }
        }

        if(this.props.video.percentWatched != 100 || this.props.video.answer_correct != '1') videoItemClasses.push('hide-print');

        return <div className={videoItemClasses.join(' ')}>
            <div className="content-wrap">
                {title}

                <div className="flexrow">
                    <div className="col">
                        { Helper.secondsToHm(this.props.video.length) + ' ' + Text.elearning_status.hours }
                    </div>

                    <div className="col">
                        { Helper.secondsToHm( (this.props.video.length_watched > this.props.video.length ? this.props.video.length : this.props.video.length_watched ) ) + ' ' + Text.elearning_status.hours }
                    </div>

                    <div className="col">
                        <ProgressBar progress={ this.props.video.percentWatched } questionsAnswered={this.props.video.answer_correct} />
                    </div>

                    {lastWatchedDate}
                </div>

                <div className="flexrow long">
                    <div className="questions">
                        <span className={questionWrapClasses.join(' ')} dangerouslySetInnerHTML={{__html: questionAnswerCorrect}}></span>
                        <span className="print-message show-print">{printMessage}</span>
                    </div>
                </div>
            </div>

            {extraVideoList}
        </div>;
    }
}

export default VideoItem;
