import React from 'react';
import Text from './../../Models/Text';

class Download extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            open: false
        }
    }

    toggleList() {
        this.setState({ open: !this.state.open });
    }

    iconForExtension(extension) {
        let iconName = '';

        switch(extension) {
            case "doc":
            case "docx":
                iconName = "file-word-o";
            break;

            case "xls":
            case "xlsx":
                iconName = "file-excel-o";
            break;

            case "ppt":
            case "pptx":
                iconName = "file-powerpoint-o";
            break;

            case "pdf":
                iconName = "file-pdf-o";
            break;

            case "zip":
                iconName = "file-archive-o";
            break;

            case "jpg":
            case "jpeg":
            case "gif":
            case "png":
            case "bmp":
                iconName = "file-image-o";
            break;
        }

        return iconName;
    }

    render() {
        let downloadsList   = [];
        let listClasses     = ['large-6', 'medium-6', 'small-12', 'columns', 'list-toggle'];
        let element, toggler;

        let folderName = this.props.file.name;

        if(this.props.file.meta && this.props.file.meta.de.title.length > 0) folderName = this.props.file.meta.de.title;

        toggler = <h4 onClick={this.toggleList.bind(this)}><i className="lnr lnr-paperclip"></i> <span>{folderName}</span> <i className="icon icon-arrow-up"></i></h4>

        if(this.props.file.type == 'folder') {
            this.props.file.files.forEach((_file) => {
                let fileName = _file.name;

                if(_file.meta && _file.meta.de.title.length > 0) fileName = _file.meta.de.title;

                if(this.props.userHasAccess) {
                    downloadsList.push(<li key={_file.id}><i className={'fa fa-lg fa-' + this.iconForExtension(_file.extension)}></i><a target='_blank' href={_file.path}>{fileName}</a></li>);
                } else {
                    downloadsList.push(<li key={_file.id}><i className={'fa fa-lg fa-' + this.iconForExtension(_file.extension)}></i>{fileName}</li>);
                }
            });
        } else {
            let fileName = this.props.file.name;

            if(this.props.file.meta && this.props.file.meta.de.title.length > 0) fileName = this.props.file.meta.de.title;

            if(this.props.userHasAccess) {
                downloadsList.push(<li key={this.props.file.id}><a target='_blank' href={this.props.file.path}>{fileName}</a></li>);
            } else {
                downloadsList.push(<li key={this.props.file.id}>{fileName}</li>);
            }
        }

        if(this.state.open) listClasses.push('open');
        
        return <div className={listClasses.join(' ')}>
                {toggler}

                <ul>{downloadsList}</ul>
            </div>
    }
}

export default Download;