import React from 'react';
import Text from './../Models/Text';
import Download from './Downloads/Download';

class VideoDownloads extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        let downloadsList = [];

        if(this.props.video.downloads) {
            this.props.video.downloads.forEach((file) => {
                if(file) {
                    downloadsList.push(<Download key={file.id} userHasAccess={this.props.userHasAccess} file={file} />);
                }
            });
        }
        
        return <div className='video__premium_downloads'>
                <h3>{(this.props.userHasAccess ? Text.downloads.access : Text.downloads.noaccess)}</h3>

                {downloadsList}
            </div>
    }
}

export default VideoDownloads;