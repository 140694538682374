import { observable, extendObservable } from 'mobx';
import axios from 'axios';

class Text {
    @observable ready = false;

    load() {
        axios.get('/api/text?file=application')
            .then((response) => {
                extendObservable(this, response.data.application);
                this.ready = true;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

const text = new Text();
text.load();
window.text = text;
export default text;
