import React from 'react';
import axios from 'axios';
import Helper from '../../Helper';
import Text from './../../Models/Text';
import Question from './Question';
import ProgressBar from '../ProgressBar';

import {vsprintf} from 'sprintf-js';

class Questions extends React.Component {
    /**
     * Constructor
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            step: 0,
            userAnswers: [],
            sumCorrectAnswers: null,
            sumCorrectPercentage: 0,
            success: null,
            percentage: 0
        }
    }

    /**
     * Component did mount
     */
    componentDidMount() {}

    /**
     * Change step to next question after getting the answer
     * for the current question
     */
    clickCallback = (answerIndex, questionIndex) => {

        document.querySelector('p.hint').innerHTML = 'Eine oder mehrere Antworten können richtig sein.';
        document.querySelector('p.hint').style.color = '#fff';
        let objLi = document.querySelector('li[data-answer="'+answerIndex+'"]');
        if (this.state.userAnswers[questionIndex] === undefined || this.state.userAnswers[questionIndex] === null) {
            this.state.userAnswers[questionIndex] = answerIndex;
            objLi.classList.add('chosen');
        } else {
            delete this.state.userAnswers[questionIndex];
            objLi.classList.remove('chosen');
        }
    }

    clickMultipleCallback = (answerIndex, questionIndex) => {

        document.querySelector('p.hint').innerHTML = 'Eine oder mehrere Antworten können richtig sein.';
        document.querySelector('p.hint').style.color = '#fff';

        if (this.state.userAnswers[questionIndex] === undefined || this.state.userAnswers[questionIndex] === null) {
            this.state.userAnswers[questionIndex] = [];
        }

        let objLi = document.querySelector('li[data-answer="'+answerIndex+'"]');
        if (this.state.userAnswers[questionIndex].indexOf(answerIndex) === -1) {
            this.state.userAnswers[questionIndex].push(answerIndex);
            objLi.classList.add('chosen');
        } else {
            let pos = this.state.userAnswers[questionIndex].indexOf(answerIndex);
            this.state.userAnswers[questionIndex].splice(pos, 1);
            objLi.classList.remove('chosen');
        }
        console.log(this.state.userAnswers)
    }

    clickButtonCallback = (questionIndex) => {

        if ((this.state.userAnswers[questionIndex] === undefined || this.state.userAnswers[questionIndex] === null) || (typeof this.state.userAnswers[questionIndex] === 'object' && !this.state.userAnswers[questionIndex].length)) {
            document.querySelector('p.hint').innerHTML = 'Bitte wählen Sie eine oder mehrere Antworten aus.';
            document.querySelector('p.hint').style.color = 'tomato';
            return null;
        }


        let step = (this.state.step + 1);
        this.setState({
            step: step,
            userAnswers: this.state.userAnswers
        });
        this.checkAnswers(step, this.state.userAnswers);
    }

    /**
     * Check given answers
     */
    checkAnswers = (step, userAnswers) => {
        /**
         * Parse progress
         */
        let progress = ((step / this.props.video.questions.length) * 100);

        /**
         * Show result
         */
        if(progress >= 100 && this.state.sumCorrectAnswers === null) {
            axios.get('/videoapi/video/question/check', {
                params: {
                    videoID: this.props.video.id,
                    answers: userAnswers
                }
            })
            .then((response) => {
                if(response.data) {
                    this.setState({
                        sumCorrectAnswers: response.data.sumCorrect,
                        sumCorrectPercentage: response.data.percentage,
                        success: response.data.success,
                        percentage: response.data.percentage
                    });

                    this.props.questionCallback(this.props.video.id, response.data);
                }
            });
        }

        this.setState({
            progress: progress
        });
    }

    /**
     * Render
     */
    render() {
        /**
         * Parse questions
         */
        let questions = [];
        let result;

        this.props.video.questions.forEach((question, key) => {
            questions.push(<Question key={key} questionKey={key} question={question} clickCallback={this.clickCallback} clickMultipleCallback={this.clickMultipleCallback} clickButtonCallback={this.clickButtonCallback} />);
        });

        let showQuestion = questions[this.state.step];

        if(this.state.success !== null) {
            let text = '';
            let button;

            if(this.state.success) {
                text = Text.questions.answers_correct;
            } else {
                text = Text.questions.answers_not_correct;

                button = <button className="button" onClick={this.props.closeQuestionsCallback}>{Text.questions.close_overlay}</button>
            }

            result = <div className="result">
                    <h2>{text}</h2>
                    {button}
                </div>
        }

        let nextStep = (this.state.step == this.props.video.questions.length ? this.props.video.questions.length : (this.state.step + 1) );
        let progressSteps = vsprintf(Text.questions.progress_step_text, [nextStep, this.props.video.questions.length]);

        return <div className='video-questions'>
            <div className="head">
                <h2>{Text.questions.headline}</h2>
                <div className="question-progress">
                    <ProgressBar progress={this.state.progress} />
                    <span className="progress-step-text">{progressSteps}</span>
                </div>
            </div>
            <div className="content">
                {result}
                <div className="question-list">
                    {showQuestion}
                </div>
            </div>
        </div>
    }
}

export default Questions;