import $ from 'jquery';

if($('body').find('.deepmenu').length > 0) {
    let pages = [];

    $('.deepmenu .level_2').find('li[data-id]').each((index, element) => {
        pages.push($(element).data('id'));
    });
    $('.deepmenu .level_1').find('li[data-id]').each((index, element) => {
        pages.push($(element).data('id'));
    });
    $('.deepmenu .level_2').find('li[data-id]').addClass('loading');
    $('.deepmenu .level_1').find('li[data-id]').addClass('loading');

    $.ajax({
        url: '/videoapi/progressfornavigation',
        method: 'GET',
        async: true,
        data: {
            pages: pages
        },
        success: (data) => {
            let percentages = [];

            data.forEach((videoData) => {

                if (!percentages[videoData.parentPage]) percentages[videoData.parentPage] = [];

                let text = '';

                if (videoData.hours || videoData.mins) {
                    text += videoData.hours+':'+videoData.mins+' Stunden';
                }

                if(videoData.percentage) {
                    percentages[videoData.parentPage].push(videoData.percentage);
                    text += ' / ' + videoData.percentage + '%' + ' angesehen';
                }

                $('.deepmenu li[data-id=' + videoData.page + ']').find('.percentage .num').text(text);
                if(parseFloat(videoData.percentage) > 0) $('.deepmenu li[data-id=' + videoData.page + ']').find('.percentage').addClass('progress');
            });

            // Parent pages
            percentages.forEach((combinedData, parentPageID) => {
                let combinedPercentage = 0;

                combinedData.forEach((percentage) => {
                    combinedPercentage += parseFloat(percentage);
                });

                combinedPercentage = parseFloat((combinedPercentage / combinedData.length)).toFixed(2);

                $('.deepmenu li[data-id=' + parentPageID + ']').find('.percentage .num').text(combinedPercentage);
                
                if(parseFloat(combinedPercentage) > 0) $('.deepmenu li[data-id=' + parentPageID + ']').find('.percentage').addClass('progress');
                // if(parseFloat(combinedPercentage) <= 0) $('.deepmenu li[data-id=' + parentPageID + ']').find('.percentage').remove();
            });

            $('.deepmenu .level_2').find('li[data-id]').removeClass('loading');
            $('.deepmenu .level_1').find('li[data-id]').removeClass('loading');
            // $('.deepmenu li').find('.percentage:not(.progress)').remove();
        }
    })
}

(function (){
    let objSearch = document.querySelector('.menubar .submit.fa-search');
    let objSearchbar = document.querySelector('.searchbar');
    if (objSearch && objSearchbar) {
        objSearch.addEventListener('click', function () {
            if (this.classList.contains('active')) {
                this.classList.remove('active')
                objSearchbar.classList.remove('active');
            } else {
                this.classList.add('active')
                objSearchbar.classList.add('active');
                setTimeout(function () {
                    objSearchbar.querySelector('input').focus();
                }, 50);
            }
        })
    }
})();