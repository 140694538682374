import React from 'react';
import axios from 'axios';
import { observable, computed, extendObservable } from 'mobx';
import Helper from '../Helper';
import { Player, ControlBar, ReplayControl,
    ForwardControl, CurrentTimeDisplay,
    TimeDivider, VolumeMenuButton
} from 'video-react';
import ResolutionButton from './VideoPlayer/ResolutionButton';
import VideoList from './VideoPlayer/VideoList';
import VideoThumbnail from './VideoPlayer/VideoThumbnail';
import VideoBuyInfo from './VideoPlayer/VideoBuyInfo';
import VideoProgress from './VideoPlayer/VideoProgress';
import VideoDownloads from './VideoDownloads';
import Text from './../Models/Text';
import Questions from './VideoPlayer/Questions';


const defaultResolution = '720';


class VideoPlayer extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.trackingInterval   = null;
        this.playerRef          = React.createRef();
        this.initialStateChange = false;

        this.state = {
            isCollection: false,
            currentVideo: null,
            premiumVideoData: null,
            playingVideo: null,
            source: '',
            currentTime: 0,
            thumbnailIsVisible: true,
            userHasAccess: false,
            player: null,
            userHasControls: true,
            disabledTracking: false,
            userDisabledTracking: false,
            error: false,
            errorMessage: '',
            errorMaxVideosWatched: false,
            forceOpenQuestions: false,
            forceCloseQuestions: false,
            allowedVideos: []
        }
    }

    componentDidMount() {
        let videoData       = JSON.parse(document.getElementById('json-' + this.props.videoID).innerHTML);
        let currentVideo    = null;
        let userHasAccess   = (videoData.type === 'free' ? true : videoData.userHasAccess);
        let videoIsBuyable  = true;

        this.refs.player.subscribeToStateChange(this.handleStateChange.bind(this));

        if(videoData.collection) {
            currentVideo                    = videoData.videos[0].orderSRC[0];
            currentVideo['premiumVideoID']  = videoData.videos[0].id;
        } else {
            currentVideo                    = (videoData.orderSRC ? videoData.orderSRC[0] : videoData);
            currentVideo['premiumVideoID']  = videoData.id;
        }

        if(videoData.collection && videoData.collection.digistoreid == '0' && !videoData.collection.userHasAccess) userHasAccess = false;
        if(videoData.digistoreid == '0' && !videoData.userHasAccess) userHasAccess = false;

        if(videoData.collection) {
            this.setState({
                userHasAccess: userHasAccess,
                isCollection: true,
                premiumVideoData: videoData.collection,
                currentVideo: currentVideo,
                videos: videoData.videos,
                source: currentVideo.path + '/video_' + defaultResolution + 'p.mp4',
                videoIsBuyable: videoIsBuyable
            });
        } else {
            this.setState({
                userHasAccess: userHasAccess,
                premiumVideoData: videoData,
                currentVideo: currentVideo,
                videos: [videoData],
                source: (currentVideo.path ? currentVideo.path : '') + '/video_' + defaultResolution + 'p.mp4',
                videoIsBuyable: videoIsBuyable
            });
        }
    }

    handleStateChange(state, prevState) {
        if(!this.state.userHasControls) {
            if((state.currentTime + 1) < this.state.currentTime) {
                if(this.state.currentVideo.progress != 100) {
                    state.currentTime = this.state.currentTime;
                    this.refs.player.seek(state.currentTime);
                }
            }
        }

        this.setState({ player: state });

        if(state.paused != prevState.paused) {
            this.trackProcess(state, prevState);
            
            if(state.currentTime == state.duration) {
                let currentVideo        = this.state.currentVideo;
                currentVideo.progress   = 100;

                this.setState({
                    currentVideo: currentVideo,
                    userHasControls: true,
                    currentTime: 0
                });
            }
        }
    }

    trackProcess(state, prevState) {
        let playerData = state;

        if(!playerData.paused) {
            this.trackingInterval = setInterval(() => {
                let currentSRC = this.refs.player.video.props.player.currentSrc;

                this.sendTrackingData(currentSRC);

            }, 5000);
        } else {
            if(playerData.currentTime == playerData.duration) {
                this.sendTrackingData(playerData.currentSrc);
            }

            clearInterval(this.trackingInterval);
        }
    }

    sendTrackingData(currentSRC) {

        // if(!this.state.player.duration || this.state.player.duration <= 0) {
        //     this.setState({ error: true });
            
        //     return;
        // }

        try {
            let currentVideosStates = localStorage.getItem('videoStates');
            currentVideosStates = currentVideosStates ? JSON.parse(currentVideosStates) : {};
            currentVideosStates[this.props.videoID] = {
                currentTime: this.state.player.currentTime,
                duration: this.state.player.duration
            }
            localStorage.setItem('videoStates', JSON.stringify(currentVideosStates));
        } catch (e) {}

        if(!this.state.player ||
            this.state.userHasControls ||
            !this.state.player.duration ||
            this.state.player.duration <= 0) {
                return;
        }

        axios.post('/videoapi/video/track', {
            currentTime: this.state.player.currentTime,
            duration: this.state.player.duration,
            source: currentSRC,
            videoID: this.props.videoID,
            videoType: this.props.videoType,
            premiumVideoID: this.state.currentVideo.premiumVideoID
        })
        .then((response) => {
            if(typeof response.data.success !== 'undefined') {
                if(!response.data.success) {
                    this.refs.player.pause();

                    this.setState({
                        error: true,
                        errorMessage: response.data.error
                    });
                }
            }
        });
    }

    getVideoWithResolution = (options = {}) => {
        if (!options.currentTime) {
            this.loadVideoWithResolution(options);
        } else {
            this.loadVideoWithResolution(options, false);
        }
    }

    // ===========================
    // Load a new video with a specifiy resolution
    // ===========================
    loadVideoWithResolution = (options = {}, doReload=true) => {

        const { player } = this.refs.player.getState();

        let _defaultResolution = defaultResolution;
        if (options.currentVideo) {
            if (!window.mobileCheck() && options.currentVideo['has1080p']) {
                _defaultResolution = '1080';
            }
        }

        let videoMeta           = null;
        let resolutionChange    = (options.resolutionChange ? options.resolutionChange : false);
        let videoSource         = options.path || player.currentSrc;
        let resolution          = options.resolution || _defaultResolution;
        let currentTime         = (resolutionChange ? player.currentTime : (options.currentTime ? options.currentTime : 0));
        let currentVideo        = (options.currentVideo ? options.currentVideo : false);

        if (currentVideo && currentVideo.progress == 100) currentTime = 0;

        if(options.path) videoSource = options.path + '/video_' + resolution + 'p.mp4';

        let stateData = {
            currentTime: currentTime,
            thumbnailIsVisible: false,
            error: false,
            forceOpenQuestions: false,
            forceCloseQuestions: false
        }

        // ==============================
        // Check if the resolution should be changed
        // ----- ----- ----- ----- -----
        // When the resolution didn't change,
        // change and load new source if the submitted
        // source is different to the current source
        // ==============================
        if(player.currentSrc) {
            if(player.currentSrc.indexOf(resolution) <= -1 || player.currentSrc.indexOf(videoSource) <= -1) {
                stateData.source = videoSource;
    
                if(currentVideo) stateData.currentVideo = currentVideo;
            }
        } else {
            stateData.source = videoSource;
        }

        this.setState(stateData);

        videoMeta = axios.post('/videoapi/videometa', {
            videoSource: videoSource,
            videoFolderID: currentVideo.id,
            videoID: this.state.premiumVideoData.id,
            videoType: this.state.premiumVideoData.type
        });

        if(this.state.premiumVideoData && this.state.premiumVideoData.type != 'free') {
            this.setState({
                userHasControls: false,
                disabledTracking: true,
                userDisabledTracking: false
            });

            if (doReload) {
                this.refs.player.load();
            }
            this.refs.player.play();

            videoMeta.then((response) => {

                let userHasControls         = (currentVideo && currentVideo.progress == 100 ? true : false);
                let disabledTracking        = response.data.disableTracking;
                let userDisabledTracking    = response.data.userDisableTracking;
                let currentTime             = parseFloat(response.data.watched);

                // When limit is set, we have a free user
                if(typeof response.data.isAllowedToWatch !== 'undefined') {
                    if(!response.data.isAllowedToWatch) {
                        this.setState({
                            errorMaxVideosWatched: true
                        });
                    }
                }

                if(!this.state.userHasAccess) userHasControls = true;
                
                if(!userHasControls) userHasControls = response.data.userHasControls;
                if(!userHasControls) userHasControls = disabledTracking;
                if(!userHasControls) userHasControls = userDisabledTracking;

                if(userHasControls) {
                    if (options.currentVideo['premiumVideoID'] && !options.currentVideo['isPreview']) {
                        try {
                            let currentVideosStates = localStorage.getItem('videoStates');
                            currentVideosStates = currentVideosStates ? JSON.parse(currentVideosStates) : {};
                            currentTime = currentVideosStates[options.currentVideo.premiumVideoID]['currentTime'];
                            this.refs.player.seek(currentTime);
                        } catch (e) {}
                    }
                }

                stateData['userHasControls'] = userHasControls;
                stateData['disabledTracking'] = disabledTracking;
                stateData['userDisabledTracking'] = userDisabledTracking;
                stateData['currentTime'] = currentTime;

                this.setState(stateData);
            });
        } else {
            if (doReload) {
                this.refs.player.load();
            }
            this.refs.player.play();

            videoMeta.then((response) => {
                // When limit is set, we have a free user
                if(typeof response.data.isAllowedToWatch !== 'undefined') {
                    if(!response.data.isAllowedToWatch) {
                        this.setState({
                            errorMaxVideosWatched: true
                        });
                    }
                }

                stateData['userHasControls'] = true;
                stateData['disabledTracking'] = true;
                stateData['userDisabledTracking'] = false;

                this.setState(stateData);
            });
        }
    }

    /**
     * Callback fired after the check response from the server
     * arrived. Data contains info if the user succeeded or not
     */
    questionCallback = (videoID, data) => {
        setTimeout(() => {
            let videos = this.state.videos;

            videos.forEach((_video) => {
                if(_video.id == videoID) {
                    _video.questions_answered = (data.success ? '1' : '0');
                }
            });

            this.setState({
                videos: videos
            });
        }, 3000);
    }

    /**
     * Show questions overlay
     */
    openQuestionsCallback = () => {
        this.refs.player.pause();

        this.setState({
            forceOpenQuestions: true,
            forceCloseQuestions: false
        });
    }

    closeQuestionsCallback = () => {
        this.setState({
            forceCloseQuestions: true
        });
    }

    openPopupLogin = (event) => {
        event.preventDefault();

        window.popup.openContent('login');
    }

    render() {
        let videoSources = [];
        let poster, videoProgress, currentVideo, downloads, error;
        let playerWrapClasses = ['player-wrap'];
        let videoThumbnail, disabledTrackingInformation, videoList, questions;

        let player = <Player ref="player"></Player>

        if(this.state.currentVideo && !this.state.currentVideo.isYoutube) {
            videoList = <VideoList userHasAccess={this.state.userHasAccess} loaded={false} />
        }

        let videoBuyInfo = null;
        let bigThumbnailClasses = ['video-thumbnail-big'];

        if(this.state.thumbnailIsVisible) videoThumbnail = <VideoThumbnail loaded={false} className={bigThumbnailClasses.join(' ')} />

        // ==============================
        // If source is available, set video source
        // ==============================
        if(this.state.source) {
            if(this.state.premiumVideoData.digistoreid != '0' || this.state.premiumVideoData.userHasAccess) {
                let singleSource = this.state.source;
                if(singleSource.indexOf('/files') <= -1) singleSource = '/' + singleSource;

                videoSources.push(<source key={1} src={singleSource} type="video/mp4" />);
            }

            poster = this.state.currentVideo.path + '/video_thumbnail.jpg?w=1280&h=720';
        }

        // ==============================
        // If video is available, render playlist
        // ==============================
        if(this.state.videos && this.state.player) {
            if(this.state.currentVideo && !this.state.currentVideo.isYoutube) {
                videoList = <VideoList allowedVideos={this.state.allowedVideos} openQuestionsCallback={this.openQuestionsCallback} userDisableTracking={this.state.userDisabledTracking} userHasAccess={this.state.userHasAccess} userHasControls={this.state.userHasControls} player={this.state.player} loaded={true} onPlayVideo={this.loadVideoWithResolution} videos={this.state.videos} />
            }
        }

        // ==============================
        // If video is available, and user has
        // access to video -> render global progress bar
        // ==============================
        if(this.state.videos && this.state.player && this.state.userHasAccess) {
            videoProgress = <VideoProgress userHasAccess={this.state.userHasAccess} player={this.state.player} videoID={this.props.videoID} videoType={this.props.videoType} />
        }

        // ==============================
        // If video is available, and the thumbnail
        // is available -> render video thumbnail
        // ==============================
        if(!this.state.thumbnailIsVisible) bigThumbnailClasses.push('hidden');
        if(this.state.player && this.state.player.paused) bigThumbnailClasses.push('show-play');

        if(this.state.videos) {
            videoThumbnail = <VideoThumbnail premiumVideoID={this.state.currentVideo.premiumVideoID} video={this.state.currentVideo} loaded={true} onPlayVideo={this.getVideoWithResolution} className={bigThumbnailClasses.join(' ')} image={poster} />
        }

        // ==============================
        // If user has no access
        // -> render buy info box
        // ==============================
        if(this.state.userHasAccess === false) {
            videoBuyInfo = <VideoBuyInfo loaded={false} />

            playerWrapClasses.push('not-logged-in');
        }

        // ==============================
        // If video has premium data and user has no
        // access -> render buy info with detail data
        // ==============================
        if(this.state.premiumVideoData && ( !this.state.userHasAccess || (this.state.premiumVideoData.type == 'free' && this.state.premiumVideoData.addLink) ) ) {
            videoBuyInfo = <VideoBuyInfo loaded={true} video={this.state.premiumVideoData} />

            // ==============================
            // We need to force this class so the buy info box
            // stays all the time
            // ==============================
            if(this.state.premiumVideoData.type == 'free' && this.state.premiumVideoData.addLink) {
                playerWrapClasses.push('not-logged-in');
            }
        }

        // ==============================
        // If user gets tracked AND user has not
        // finished this video -> hide control bar
        // ==============================
        let controlBarClass = 'no-skip';

        if(this.state.userHasControls) controlBarClass = 'skip';

        // ==============================
        // When the user has disabled the tracking
        // we have to show an information
        // ==============================
        if(this.state.userDisabledTracking) {
            disabledTrackingInformation = <div className='notification notification-disabled-tracking'><p>{Text.video.disabled_tracking}</p></div>
        }

        if(this.state.currentVideo) {
            if(this.state.premiumVideoData.downloads && this.state.premiumVideoData.downloads.length > 0) {
                downloads = <VideoDownloads userHasAccess={this.state.userHasAccess} video={this.state.premiumVideoData} />
            }
        }

        /**
         * Questions for video
         */
        if( (this.state.player &&
            this.state.userHasAccess &&
            this.state.videos &&
            this.state.player.ended &&
            !this.state.userDisabledTracking) || this.state.forceOpenQuestions ) {

            /**
             * Only show questions after last video file from
             * premium video
             */
            this.state.videos.forEach((_video) => {
                if(_video.questions.length <= 0) return;
                if(_video.questions_answered == '1') return;

                let lastVideoNumFiles   = _video.orderSRC.length;
                let lastVideoID         = _video.orderSRC[(lastVideoNumFiles - 1)]['id'];

                let currentVideoID      = this.state.currentVideo.id;

                if( (lastVideoID == currentVideoID) || this.state.forceOpenQuestions ) {
                    let allVideosWatched = true;

                    _video.orderSRC.forEach((_videoFile) => {
                        if(_videoFile.progress < 100 && _videoFile.disableTracking != '1') allVideosWatched = false
                    });

                    if(allVideosWatched) questions = <Questions closeQuestionsCallback={this.closeQuestionsCallback} questionCallback={this.questionCallback} video={_video} />
                }
            });
        }

        if(this.state.forceCloseQuestions) questions = null;

        /**
         * Video player
         */
        if(this.state.currentVideo && !this.state.currentVideo.isYoutube) {
            player = <Player ref="player" autoHide={false} preload="none" autoPlay={false} playsInline poster={null} startTime={this.state.currentTime}>
                {videoSources}
                {videoThumbnail}
                <ControlBar className={controlBarClass}>
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <ResolutionButton className='resolution-button' resolutionChangeCallback={this.loadVideoWithResolution} order={7} video={this.state.currentVideo} />
                </ControlBar>
            </Player>
        } else {
            if(this.state.currentVideo) {
                player = <iframe frameBorder='0' allowFullScreen className='youtube-frame' src={'https://www.youtube.com/embed/' + this.state.currentVideo.youtubeID}></iframe>
            }
        }

        if(this.state.error) {
            switch(this.state.errorMessage) {
                case 'no_user':
                    error = <div className='error'>
                        <p dangerouslySetInnerHTML={{__html: Text.video.error.no_user}}></p>
                    </div>
                break;

                default:
                    error = <div className='error'><p dangerouslySetInnerHTML={{__html: Text.video.error.error_video_playback}}></p></div>
                break;
            }
        }

        if(this.state.errorMaxVideosWatched) {
            error = <div className='error'>
                <p dangerouslySetInnerHTML={{__html: Text.video.error.error_video_max_watched}}></p>
                <p className="long"><a onClick={this.openPopupLogin.bind(this)} className="button opener login" data-popup="login" href="#">{Text.video.register_now}</a></p>
            </div>
        }

        if(this.state.premiumVideoData && this.state.premiumVideoData.digistoreid == '0' && !this.state.premiumVideoData.userHasAccess) {
            disabledTrackingInformation = <div className='notification notification-disabled-tracking'><p>{Text.video.not_activated_single}</p></div>
            videoList                   = null;
            videoBuyInfo                = null;

            playerWrapClasses.push('no-digistoreid');
        }

        if(this.state.premiumVideoData && this.state.premiumVideoData.type != 'free') {
            if(this.state.premiumVideoData.type != 'collection' && !this.state.premiumVideoData.showPrice) {
                videoBuyInfo = null;

                playerWrapClasses.push('full-width');
            }
        }

        if(this.state.premiumVideoData && this.state.premiumVideoData.type == 'free') videoProgress = null;

        let reportVideo;

        if(this.state.userHasAccess !== false) {
            reportVideo = <a href={"/videos-werden-nicht-abgespielt.html?ref=" + window.location.href} className="video-play-error">{Text.video.error.no_video_button}</a>
        }

        return <div className="player">
                <div className={playerWrapClasses.join(' ')}>
                    {error}
                    {disabledTrackingInformation}
                    {questions}

                    {player}

                    {videoBuyInfo}
                </div>

                {videoList}

                {reportVideo}

                {videoProgress}
                {downloads}
        </div>
    }
}

export default VideoPlayer;