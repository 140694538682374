import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

const propTypes = {
    player: PropTypes.object,
    className: PropTypes.string,
};

const defaultResolution = '720';

export default class ResolutionButton extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            open: false,
            currentResolution: defaultResolution
        }
    }

    // ==============================
    // Show resolution menu to user
    // ==============================
    showResolutionMenu(currentSrc, event) {
        event.preventDefault();

        let currentResolution = defaultResolution;
        if(currentSrc.indexOf('480p') > -1) currentResolution = '480';
        if(currentSrc.indexOf('1080p') > -1) currentResolution = '1080';

        this.setState({
            open: !this.state.open,
            currentResolution: currentResolution
        });
    }

    changeResolution(changeToResolution) {
        this.setState({ currentResolution: changeToResolution });

        let videoPath   = this.props.player.currentSrc.replace('/video_480p.mp4', '');
        videoPath       = videoPath.replace('/video_720p.mp4', '');
        videoPath       = videoPath.replace('/video_1080p.mp4', '');

        this.props.resolutionChangeCallback({
            resolution: changeToResolution,
            path: videoPath,
            resolutionChange: true
        });
    }

    render() {
        const { player, className } = this.props;
        const { currentSrc } = player;

        let resolutionSwitch = [];

        // If state is open -> show resolution list
        if(this.state.open) {
            resolutionSwitch = [
                <span key='480' className={(this.state.currentResolution == '480' ? 'active' : '')} onClick={this.changeResolution.bind(this, '480')}>480p</span>,
                <span key='720' className={(this.state.currentResolution == '720' ? 'active' : '')} onClick={this.changeResolution.bind(this, '720')}>720p</span>
            ];

            if (this.props.video['has1080p']) {
                resolutionSwitch.push(<span key='1080' className={(this.state.currentResolution == '1080' ? 'active' : '')} onClick={this.changeResolution.bind(this, '1080')}>1080p</span>)
            }
        }

        return (
            <span onClick={this.showResolutionMenu.bind(this, currentSrc)} className={classNames(className, {
                'video-react-control': true,
                'video-react-button': true,
                })}
            >
                <span className='resolution-switch-wrap'>{resolutionSwitch}</span>
            </span>
        );
    }
}

ResolutionButton.propTypes = propTypes;