import { observer } from 'mobx-react';
import React from 'react';
import VideoPlayer from './VideoPlayer';
import Text from './../Models/Text';

@observer
class VideoApp extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state          = {}
        this.videoPlayer    = props.playerDOM;
    }

    render() {
        if(!Text.ready) return <div className="videos"></div>

        return <div className="videos">
            <VideoPlayer videoID={this.videoPlayer.dataset.video} videoType={this.videoPlayer.dataset.videotype} />
        </div>;
    }
}

export default VideoApp;
