import $ from "jquery";
import React from 'react';
import { observer } from 'mobx-react';
import Helper from '../../Helper';
import VideoThumbnail from './VideoThumbnail';
import Loader from 'react-loader';
import Text from './../../Models/Text';
import Slider from "react-slick";

@observer
class VideoList extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            activeSlide: 0,
            videoPath: null,
            progress: null
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if($('body').find('.player-video-list').length > 0 && window.hash && window.hash.v) {
                let index = (parseInt(window.hash.v) - 1);
                if(this.refs.slider) {
                    let element = $('.player-video-list .slick-slide[data-index="' + index + '"] .video-thumbnail');

                    if(element.length > 0) {
                        this.refs.slider.slickGoTo(index);

                        element.click();
                    }
                }
            }
        }, 500);
    }

    nextSlide() { this.refs.slider.slickNext(); }
    prevSlide() { this.refs.slider.slickPrev(); }

    static getDerivedStateFromProps(nextProps) {
        let videoPath, progress= null;

        if(nextProps.player) {
            videoPath   = Helper.getVideoPath(nextProps.player.currentSrc);
            progress    = ((nextProps.player.currentTime / nextProps.player.duration) * 100);
        }

        return {
            videoPath: videoPath,
            progress: progress
        }
    }

    render() {
        let videoList               = [];
        let videoListClass          = ['scroll-list'];
        let videoListParentClass    = ['player-video-list'];
        let badge = '';
        let prevButton, nextButton = null;

        var settings = {
            arrows: false,
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            draggable: false,
            responsive: [
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 820,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ],
            beforeChange: (key, next) => {
                this.setState({ activeSlide: next })
            }
        };

        // ===========================
        // If no free videos were selected
        // don't show any of the free videos
        // ===========================
        if(this.props.videos && (this.props.videos.length == 1 && this.props.videos[0].orderSRC && this.props.videos[0].orderSRC.length <= 1)) return <div></div>
        if(this.props.videos && (this.props.videos.length == 1 && this.props.videos[0].previewFirst == 0) && !this.props.userHasAccess) return <div></div>

        // ===========================
        // If 'showFreeVideos' ist set only display the first x video
        // from 'previewFirst'. Skip all other videos
        // ===========================
        if(this.props.userHasAccess) videoListParentClass.push('has-access');

        if(this.props.loaded && this.props.videos) {
            this.props.videos.forEach((premiumVideo) => {
                let openQuestionsButton, watchProgress = null;
                let allVideosWatched = true;

                if(premiumVideo.orderSRC) {
                    premiumVideo.orderSRC.forEach((video, key) => {
                        let addVideo = this.props.userHasAccess;

                        if(video.progress < 100 && video.disableTracking != '1') allVideosWatched = false
                        
                        // Check if user has access to this video
                        if(!this.props.userHasAccess) {
                            // When the video has set a number of free videos,
                            // check if the current video is inside of this number
                            // and add the video to the videolist
                            if(parseInt(premiumVideo.previewFirst) != 0 && (key + 1) <= parseInt(premiumVideo.previewFirst)) {
                                addVideo = true;
                            }
                        }

                        if(premiumVideo.questions.length > 0 && !this.props.userDisableTracking) {
                            let lastVideoNumFiles   = premiumVideo.orderSRC.length;
                            let lastVideoID         = premiumVideo.orderSRC[(lastVideoNumFiles - 1)]['id'];

                            if(lastVideoID == video.id) {
                                if(premiumVideo.questions_answered != '1') {
                                    openQuestionsButton = <button disabled={!allVideosWatched} className="button" onClick={this.props.openQuestionsCallback}>{Text.questions.goto_questions}</button>
                                }
                            }
                        }
        
                        if(addVideo) {
                            // When the video source is equal to the current video
                            // set the videolist-item as active
                            if(this.state.videoPath && this.state.videoPath == Helper.getVideoPath(video.path)) {
                                videoList.push(<li key={key}>
                                    <VideoThumbnail premiumVideo={premiumVideo} userHasAccess={this.props.userHasAccess} userHasControls={this.props.userHasControls} progress={this.state.progress} className='active' loaded={true} onPlayVideo={this.props.onPlayVideo} video={video} image={video.thumbnailImage} />
                                    {openQuestionsButton}
                                </li>);
                            } else {
                                videoList.push(<li key={key}>
                                    <VideoThumbnail premiumVideo={premiumVideo} userHasAccess={this.props.userHasAccess} userHasControls={this.props.userHasControls} loaded={true} onPlayVideo={this.props.onPlayVideo} video={video} image={video.thumbnailImage} />
                                    {openQuestionsButton}
                                </li>);
                            }
                        }
                    });
                }

            });

            // When we have less or equal than 4 video in the videolist
            // we want to center the videos with displa:flex
            if(videoList.length <= 4) videoListClass.push('center');
            if(videoList.length <= 0) videoListParentClass.push('hide');
        }

        // ==============================
        // When the user has no access:
        // => show the "preview" badge
        // ==============================
        if(!this.props.userHasAccess) {
            badge = <span className='badge'>{Text.video.preview}</span>
        }

        // ==============================
        // Custom slider arrows
        // ==============================
        if(this.state.activeSlide >= settings.slidesToShow) {
            prevButton = <button className='slick-arrow slick-prev' onClick={this.prevSlide.bind(this)}><i className="lnr lnr-chevron-left"></i><span></span></button>
        }

        if((this.state.activeSlide + settings.slidesToShow) < videoList.length) {
            nextButton = <button className='slick-arrow slick-next' onClick={this.nextSlide.bind(this)}><i className="lnr lnr-chevron-right"></i><span></span></button>
        }

        // ==============================
        // Render
        // ==============================
        return <div className={videoListParentClass.join(' ')}>

            <Loader loaded={this.props.loaded} color='#fff' lines={13} width={3} length={10}>
                <div className='slider-wrap'>
                    {prevButton}

                    <div className='slick-slider-wrap'>
                        {badge}

                        <Slider ref="slider" className={videoListClass.join(' ')} {...settings}>
                            {videoList}
                        </Slider>

                        {badge}
                    </div>

                    {nextButton}
                </div>
            </Loader>

        </div>
    }
}

export default VideoList;