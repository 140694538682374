import React from 'react';
import axios from 'axios';
import Helper from '../../Helper';
import Text from './../../Models/Text';

class Question extends React.Component {

    /**
     * Constructor
     */
    constructor(props, context) {
        super(props, context);
        this.state = {
            progress: null
        }
    }

    /**
     * Component did mount
     */
    componentDidMount() {}

    /**
     * Render
     */
    render() {
        let answers = [];

        this.props.question.answers.forEach((answer, answerKey) => {
            //if (this.props.question.multiple) {
                let len = answer.answer.length;
                let ccsClass = "question-item";
                if (len >= 48 && len < 100) {
                    ccsClass += ' large';
                }
                if (len >= 100 && len < 140) {
                    ccsClass += ' medium';
                }
                if (len >= 140) {
                    ccsClass += ' small';
                }
                answers.push(<li className={ccsClass} onClick={this.props.clickMultipleCallback.bind(this, answerKey, this.props.questionKey)} data-answer={answerKey} key={answerKey}>{answer.answer}</li>);
            //} else {
                //answers.push(<li onClick={this.props.clickCallback.bind(this, answerKey, this.props.questionKey)} data-answer={answerKey} key={answerKey}>{answer.answer}</li>);
            //}
        });

        return <div className='single-question'>
            <h2>{this.props.question.question}</h2>
            <ul>{answers}</ul>
            <p className="hint message">Eine oder mehrere Antworten können richtig sein.</p>
            <div className="align-right"><button onClick={this.props.clickButtonCallback.bind(this, this.props.questionKey)} className="button">Antwort bestätigen</button></div>
        </div>
    }
}

export default Question;