import React from 'react';
import axios from 'axios';
import Loader from 'react-loader';
import Text from './../../Models/Text';
import Helper from '../../Helper';

class VideoBuyInfo extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            video: null,
            loaded: false,
            userIsLoggedIn: false
        }
    }

    componentDidMount() {
        axios.get('/api/user')
        .then((response) => {
            if(this.state.loaded) this.setState({ userIsLoggedIn: (typeof response.data.id !== 'undefined' ? true : false) });
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(!nextProps.loaded) return { loaded: false };

        return {
            loaded: true,
            video: nextProps.video
        }
    }

    openRegistrationModal(event) {
        event.preventDefault();

        window.popup.openContent("login", "buydialog");

        return false;
    }

    checkBuyURL(buyURL, event) {
        if(buyURL <= 5) {
            axios.post('/videoapi/logbuybutton', {
                video: this.state.video
            });
        }
    }

    render() {
        let priceDetails    = [];
        let buyURL, title   = '';
        let buyTag, prices;
        let buyInfoClasses  = ['video-buy-info'];

        if(this.state.loaded) {
            buyURL  = (this.state.video.buyURL ? this.state.video.buyURL.url : '');
            title   = (this.state.video.buyButtonTitle ? this.state.video.buyButtonTitle : this.state.video.title);

            if(this.state.video.type != 'free') {
                if(this.state.video.buyButtonPaymentTitlePrefix && this.state.video.buyButtonPaymentTitle) {
                    priceDetails.push(<li key='first'>{this.state.video.buyButtonPaymentTitlePrefix} <span>{this.state.video.buyButtonPaymentTitle}</span></li>);
                }
        
                if(this.state.video.buyButtonPaymentTitleSecond) {
                    priceDetails.push(<li key='second'>{this.state.video.buyButtonPaymentTitleSecond}</li>);
                }
        
                if(this.state.video.buyButtonPaymentTitleThird) {
                    priceDetails.push(<li key='third'>{this.state.video.buyButtonPaymentTitleThird}</li>);
                }

                if(this.state.userIsLoggedIn) {
                    buyTag = <a target='_blank' onClick={this.checkBuyURL.bind(this, buyURL)} href={buyURL} className='buy'><span className="lnr lnr-cart"></span> {Text.video.buy_button}</a>
                } else {
                    buyTag = <a onClick={this.openRegistrationModal} className='buy'><span className="lnr lnr-cart"></span> {Text.video.buy_button}</a>
                }

                prices = <ul className='price-details'>{priceDetails}</ul>
            } else {
                buyInfoClasses.push('free');
                
                if(this.state.video.addLink) {
                    buyTag = <a href={this.state.video.linkToUrl} className='buy'><span className="lnr lnr-arrow-right"></span> {this.state.video.linkText}</a>
                }
            }
        } else {
            buyInfoClasses.push('full-width');
        }

        if(this.state.video && this.state.video.digistoreid == '0') buyTag = '';

        return <div className={buyInfoClasses.join(' ')}>
            <Loader loaded={this.state.loaded} lines={13} width={3} length={10}>
                <h2 dangerouslySetInnerHTML={{__html: title}}></h2>
                {prices}
                {buyTag}
            </Loader>
        </div>
    }
}

export default VideoBuyInfo;