import { observer } from 'mobx-react';
import React from 'react';
import Text from './../Models/Text';
import axios from 'axios';
import Loader from 'react-loader';
import YearItem from './ElearningStatus/YearItem';

@observer
class ElearningStatusApp extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            items: {},
            address: {},
            loaded: false,
            user: '',
            logo: '',
            memberLogo: '',
            hasTrackingData: true
        }
    }

    componentDidMount(){
        axios.get('/videoapi/elearning-status?ct='+this.props.type)
        .then(result => {
            this.setState({
                items: result.data.tracking,
                user: result.data.user,
                address: result.data.address,
                loaded: true,
                logo: result.data.logo,
                memberLogo: result.data.memberLogo,
            });
            if(result.data.tracking.length <= 0) {
                this.setState({ hasTrackingData: false });
            }
        });
    }

    render() {
        let years, userText;

        if(Text.ready && this.state.items) {
            let _keys = Object.keys(this.state.items);
            _keys.sort(function(a, b){return b-a});
            years = _keys.map((year)=>{
                let lengthWatched               = 0;
                let lengthWatchedCompleteVideo  = 0;

                this.state.items[year].map(item => {
                    if(item.videos) {
                        item.videos.forEach((premiumVideo) => {
                            if(premiumVideo.percentWatched === 100 && premiumVideo.answer_correct === '1' && item.num_questions) {
                                lengthWatchedCompleteVideo += (premiumVideo.length_watched > premiumVideo.length ? premiumVideo.length : premiumVideo.length_watched);
                            }

                            lengthWatched += (premiumVideo.length > premiumVideo.length_watched ? premiumVideo.length_watched : premiumVideo.length); // neu
                        });
                    } else {
                        if(item.percentWatched === 100 && item.answer_correct === '1' && item.num_questions) {
                            lengthWatchedCompleteVideo += (item.length_watched > item.length ? item.length : item.length_watched);
                        }
                        lengthWatched += (item.length > item.length_watched ? item.length_watched : item.length); // neu
                    }
                });

                return <YearItem
                    key={year}
                    items={this.state.items[year]}
                    year={year}
                    lengthWatched={ lengthWatched }
                    lengthWatchedCompleteVideo={ lengthWatchedCompleteVideo }
                />;
            });

            let userAddress = '';
            if (this.state.user) {
                userAddress += '<strong style="color:#124987">' + this.state.user + '</strong>' + "</br>";
            }
            if (this.state.address.company) {
                userAddress += this.state.address.company + "</br>";
            }
            if (this.state.address.street) {
                userAddress += this.state.address.street + "</br>";
            }
            if (this.state.address.postal) {
                userAddress += this.state.address.postal + ' ' + this.state.address.city + "</br>";
            }
            if (this.state.address.country) {
                userAddress += this.state.address.country;
            }

            userText = <div className="show-print"><div style={{textAlign:'left',float:'left',width:'50%'}}><p dangerouslySetInnerHTML={{__html: userAddress}}></p></div><div style={{marginBottom: '50px',textAlign:'right',float:'right',width:'50%'}}><img src={(this.state.memberLogo?this.state.memberLogo:this.state.logo)} style={{'maxHeight':'130px'}}/></div><h1 style={{clear: 'both','marginTop':'50px'}}>{Text.elearning_status.print_desc}</h1></div>
        }

        if(!this.state.hasTrackingData) {
            userText = <h3>{Text.elearning_status.no_data}</h3>
        }

        return <div className="elearning-status">
            <Loader loaded={this.state.loaded} color='#124987' lines={13} width={3} length={10}>
                {userText}

                {years}
            </Loader>
        </div>;
    }
}

export default ElearningStatusApp;
