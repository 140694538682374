import React from 'react';
import axios from 'axios';
import Loader from 'react-loader';
import Text from '../../Models/Text';

class VideoThumbnail extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            title: '',
            loaded: false,
            extraClass: '',
            progress: 0,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(!nextProps.loaded) return { loaded: false }

        let videoMeta   = nextProps.video || {};
        videoMeta       = videoMeta.meta || {};
        let videoTitle  = '';
        let progress    = 0;

        if(videoMeta.de) videoTitle = videoMeta.de.title;

        // Saved watched length
        if(nextProps.video) progress = nextProps.video.progress;

        // Current progress
        if(nextProps.progress) progress = nextProps.progress;

        if(prevState.progress > progress) progress = prevState.progress;

        // When saved progress is 100%
        // don't update progress
        if(nextProps.video && nextProps.video.progress == 100) progress = 100;

        return {
            extraClass: (nextProps.className ? nextProps.className : ''),
            title: videoTitle,
            progress: progress
        }
    }

    imageIsLoaded() {
        this.setState({ loaded: true });
    }

    // ===========================
    // Play clicked video in player
    // ===========================
    playVideo() {
        if(!this.props.video.videoIsActivated) return;

        if(this.props.video) {
            let currentVideo = this.props.video;

            if(this.props.premiumVideoID) {
                currentVideo['premiumVideoID'] = this.props.premiumVideoID;
            } else {
                currentVideo['premiumVideoID']  = this.props.premiumVideo.id;
            }

            this.props.onPlayVideo({
                path: this.props.video.path,
                currentTime: parseFloat(this.props.video.progressTime),
                currentVideo: currentVideo
            });
        } else {
            this.props.onPlayVideo();
        }
    }

    render() {
        let progress, notActivated, extraPlayButton;
        let thumbnailClasses = ['video-thumbnail'];

        //if(this.props.userHasControls) thumbnailClasses.push('has-controls');

        // Only show the progress bar when the user
        // has access to this video/collection
        if(this.props.userHasAccess && this.props.video.type != 'free' && this.props.video.disableTracking != '1') {
            if(this.state.progress == 100) thumbnailClasses.push('completed');

            progress = <div className='progress'><span style={{ width: this.state.progress + '%' }}></span></div>
        }

        if(this.props.premiumVideo && this.props.premiumVideo.questions_answered == '1') {
            thumbnailClasses.push('answered');

            extraPlayButton = <span className="image-play-button questions"><span>{Text.questions.all_answered}</span></span>
        }

        // Video is not activated yet for the user
        if(this.props.video && !this.props.video.videoIsActivated) {
            thumbnailClasses.push('not-activated');

            notActivated = <span className='not-activated-info'>{Text.video.not_activated}</span>
        }

        return <div onClick={this.playVideo.bind(this)} className={thumbnailClasses.join(' ') + ' ' + this.state.extraClass}>
            <div className='video-preview'>
                {notActivated}
                <div className="buttons">
                    <span className="image-play-button"><span>{Text.questions.watched_all}</span></span>
                    {extraPlayButton}
                </div>
                <img src={this.props.image} />
                {progress}
            </div>

            <span className="title">{this.state.title}</span>
        </div>
    }
}
// 112 -> 'thumbnail.php?p=' + this.props.image
export default VideoThumbnail;