export default {
    // ==============================
    // Get alias from current page
    // ==============================
    getPageAlias() {
        var alias   = '';
        var url     = window.location.pathname;
        url         = url.split('/');

        url.forEach((part) => {
            if(part.indexOf('.html') > -1) alias = part;
        });

        alias = alias.replace('.html', '');
        
        return alias;
    },

    // ==============================
    // Get the correct video path to match
    // with the database
    // ==============================
    getVideoPath(videoPath = '') {
        if(videoPath == null) return '';
        
        videoPath = videoPath.split(window.location.host);
        videoPath = (typeof videoPath[1] !== 'undefined' ? videoPath[1] : videoPath[0]);
        videoPath = videoPath.replace('/video_720p.mp4', '');
        videoPath = videoPath.replace('/video_480p.mp4', '');
        videoPath = videoPath.replace('/files', 'files');

        return videoPath;
    },

    /**
     * Seconds to Hour:Min
     */
    secondsToHm(d) {

        let sec_num = parseInt(d, 10); // don't forget the second param
        let hours   = Math.floor(sec_num / 3600);
        let minutes = Math.ceil((sec_num - (hours * 3600)) / 60);

        if (minutes === 60) {
            minutes = 0;
            hours++;
        }

        //var seconds = Math.ceil(sec_num - (hours * 3600) - (minutes * 60));
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        //if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes;
    }
}
